<template>
  <div class="container-fluid py-4 add-student teachers roles inst-attend">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
            <div
              class="bg-gradient-success shadow-success border-radius-lg pt-4 pb-3"
            >
              <h6 class="text-white text-capitalize ps-3">  {{$t('message.attendance')}}</h6>
            </div>
          </div>
          <div class="card-body px-0 pb-2 overflow">
            <div class="row align-center">
                    <div class="mb-3 col-lg-6">
                    <label>   {{$t('message.course')}}<span class="red">*</span></label>
                    <multiselect
                        class='required form-control'
                        id='grade'
                        v-model="selectedCourse"
                        :options='courses'
                        :searchable='true'
                        :close-on-select='true'
                        :placeholder=" $t('message.select') "
                        @select='getCourseLectures($event)'
                        label='name'
                        track-by='name'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                </div>
                   <div class="mb-3 col-lg-6">
                    <label>  {{$t('message.lectures')}}<span class="red">*</span></label>
                    <multiselect
                        class='required form-control'
                        id='lecture'
                        v-model="selectedLecture"
                        :options='activeLectures'
                        :searchable='true'
                        :close-on-select='true'
                        :placeholder=" $t('message.select')"
                        label='name'
                        track-by='id'
                        required='required'
                        :internal-search="true"
                        >
                      >
                        <template v-slot:noOptions>
                          <span> فارغة </span>
                        </template>
                        <template v-slot:noResult>
                          <span>لا يوجد نتيجة </span>
                        </template>
                      </multiselect>
                </div>
            </div>
            <!-- <div class='row times course-info text-center'>
                <div class="col-md-3" >
                  <h5 class="info-h">الطلاب المسجلين في الدورة : <span class="green info-data">{{selectedLecture?selectedLecture.counts.enrolledStudents : null}}</span></h5>
                </div>
                <div class="col-md-3" >
                  <h5 class="info-h">الحضور : <span class="green info-data">{{selectedLecture?selectedLecture.counts.totalAttendStudents : null}}</span></h5>
                </div>
                 <div class="col-md-3" >
                  <h5 class="info-h">الغياب : <span class="green info-data">{{selectedLecture?selectedLecture.counts.totalAbsence : null}}</span></h5>
                </div>
                 <div class="col-md-3" >
                  <a data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample">
                  <h5 class="info-h blue" >الإستثنائي : <span class="green info-data">{{selectedLecture?selectedLecture.counts.exceptionalStudents: null}}</span></h5>
                  </a>
                </div>
              </div> -->
                      <!-- exceptional attendance row -->
            <div class="row align-center">
              <div class="text-center col-lg-12 overflow-x">
                <table class="table b-block attend-table">
                  <thead>
                    <tr>
                      <th>{{$t('message.addAttendance')}}</th>
                      <th>  {{$t('message.id')}} </th>
                      <th>  {{$t('message.name')}}</th>
                        <th>  {{$t('message.lectureDuration')}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr  v-for="student in students" :key="student.id" :class="{'attend-back' :student?.attendance[0]?.attended }">
                       <td>
                          <vmd-checkbox
                            v-model="student.checked" @change="createAttendance(student)"
                          ></vmd-checkbox>
                          <input
                            type="checkbox"
                            v-model="student.checked"
                            class="form-control"
                          />
                        </td>
                      <td>
                        {{ student.number }}
                      </td>
                      <td>
                        <span class=" student-name">
                        {{ student.full_name }}
                        </span>
                      </td>
                       <td style="width:20%" >
                        <vmd-input v-if="!student?.attendance.length||!student?.attendance[0].attended"
                    id="inp"
                    type="text"
                    v-model='student.lectureDuration'
                    />
                    <span v-else>{{formattedDuration(student?.attendance[0]?.attended_duration)}}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VmdCheckbox from '@/components/VmdCheckbox.vue'
import VmdInput from '@/components/VmdInput.vue'
import swal from 'sweetalert'
import $ from 'jquery'
export default {
  name: 'InsAttendance',
  components: {
    VmdInput,
    VmdCheckbox
  },
  data () {
    return {
      activeLectures: [],
      selectedLecture: '',
      students: [],
      exStudent: {},
      courses: [],
      selectedCourse: '',
      lectureDuration: '',
      errors: []
    }
  },
  created () {
    this.$http.get(`${this.$hostUrl}expected-students`).then((response) => {
      this.courses = response.data.data.courses
    })
  },
  methods: {
    getStudents () {
      console.log(this.selectedCourse.duration, this.students)
      this.$http
        .get(`${this.$hostUrl}course/attendance/students?course_id=${this.selectedCourse.id}&&lecture_id=${this.selectedLecture.id}`)
        .then((response) => {
          this.students = response.data.data
          this.students.forEach((student) => {
            if (student?.attendance[0]?.attended) {
              student.checked = true
            }
            const splitTime = this.selectedCourse.duration.split(':')
            const convertedTime = splitTime[0] + ':' + splitTime[1]
            student.lectureDuration = convertedTime
          })
        })
    },
    getCourseLectures ($event) {
      const activeLectures = []
      const current = this
      this.$http
        .get(`${this.$hostUrl}active-lectures?course_id=${$event.id}`)
        .then((response) => {
          $.each(response.data.data, function (key, val) {
            activeLectures.push({
              name: 'محاضرة ' + val.lecture_number + ' ' + '||' + ' ' + 'التوقيت' + ' ' + ' ' + current.$filters.formatTime((val.start).slice(-5)),
              courseName: current.selectedCourse.name,
              course: {
                id: current.selectedCourse.id,
                name: current.selectedCourse.name
              },
              id: val.id
            })
          })
          this.activeLectures = activeLectures
          if (this.activeLectures.length === 1) {
            this.selectedLecture = {
              id: this.activeLectures[0].id,
              name: this.activeLectures[0].name,
              counts: this.activeLectures[0].counts
            }
          }
          this.getStudents()
        })
    },
    formattedDuration (duration) {
      const splitTime = duration.split(':')
      return splitTime[0] + ':' + splitTime[1]
    },
    async createAttendance (studentInfo) {
      const student = this.students.find((s) => s.id === studentInfo.id)
      console.log(student.checked)
      if (!student.checked) {
        this.cancelAttendance(studentInfo.id)
      } else {
        const formData = new FormData()
        formData.append('student_id', studentInfo.id)
        formData.append('lecture_id', this.selectedLecture.id)
        formData.append('course_id', this.selectedCourse.id)
        formData.append('attended_duration', studentInfo.lectureDuration)
        formData.append('attended', 1)
        formData.append('_method', 'POST')
        this.errors = await this.postRequest(
          formData,
          this.$hostUrl + 'attendances',
          ''
        )
        this.getStudents()
      }
    },
    async cancelAttendance (id) {
      swal({
        title: this.$t('message.delConfirm'),
        text: this.$t('message.delText'),
        icon: 'warning',
        buttons: [this.$t('message.no'), this.$t('message.yes')],
        dangerMode: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33'
      }).then((willDelete) => {
        if (willDelete) {
          this.$http
            .delete(
              `${this.$hostUrl}attendance/remove/${this.selectedLecture.id}/${id}`,
              { _method: 'delete' }
            )
            .then((response) => {
              swal({ title: response.data.message, buttons: this.$t('message.ok') })
              // refresh data
              this.getStudents()
            })
        } else {
          swal({ title: this.$t('message.delCanceled'), buttons: this.$t('message.ok') })
          // refresh data
          this.getStudents()
        }
      })
    }
  }
}
</script>
<style>
.height {
  height: 46px !important;
}
.inst-attend .form-check-input{
  width: 1.9em !important;
  height: 1.9em !important;
}
</style>
